import React from 'react'
import Layout from '../components/Layout/layout'
import SEO from '../components/SEO/seo'
const Login = () => (
	<Layout>
		<SEO title='Rides' />
		<p>Rides Screen</p>
	</Layout>
)

export default Login
